<!-- 种质资源库 1-->
<template>
  <div class="germBank">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'种质资源库管理系统'" />
    <Business :business="business" />
    <appsOfImg :appsData="appsData" />
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import appsOfImg from "./components/appsOfImg.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    appsOfImg
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner10.png"),
      business: {
        text: "种质资源库以履行《生物多样性公约》，保护生物多样性、持续利用其组成部分以及互惠共享遗传资源为建设和运行目标，成为生物多样性研究与保护的重要载体。种质资源库信息管理系统管理包括样本资源、遗传数据资源、天然产物资源、活体资源等多种资源的信息数据，并对种质资源信息数据建立安全、可行的开放分级开发共享机制，以实现互惠共享的目标。",
        Imgs: []
      },
      appsData: {
        text: "以“宝晟云”平台作为软件引擎，为不同领域的用户搭建种质资源库信息管理应用服务，根据各行业用户种质资源的种类和业务管理流程，开发规范化收集、处理、保存样本的功能模块。支持通过集成物联网应用，管理样本采集的环境数据、地理信息数据。在系统平台上构建基因数据库、蛋白组学数据库，并建设开发了数据上传审核机制和资源数据共享机制等管理功能。系统用户包括：中国科学院昆明动物研究所、南方海洋科学与工程广东省实验室（珠海）等。目前，中国科学院昆明动物研究所使用“动物种质资源信息数据管理平台”标准化采集、记录了20余万份动物样本信息及衍生数据，建立了样本数据库、衍生实验数据库、基因组数据库、蛋白组学数据库、成果数据库，并通过公共服务网站对社会分级开放共享资源信息数据，为动物遗传学、动物分类学、动物系统地理学等学科发展提供了强有力的支持。南方海洋科学与工程广东省实验室（珠海）主要工作任务是以海洋种质资源为核心，通过海洋生物种质库、海洋生物基因资源库、海洋生物产物库三大子平台的建设和运行，形成对海洋动植物和微生物，海洋天然产物等资源及其基因数据的收集、保藏能力，加强国内外资源与数据共享合作，积极推动海洋生物资源保护与开发利用。",
        imgUrl: require("@/assets/images/cases/content/apps-germBank01.png")
      },
    }
  }
}
</script>

<style lang="less" scoped>
</style>